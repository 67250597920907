import './sea-burial.scss'

import rules from "../../utils/rules";
import { mapState, mapGetters } from "vuex";

export default {
  data: () => ({
    ruleForm: {
      appointmentName: '',
      cremainsCount: 1,
      departedList: [
        {userName: '', userLocation: '', userIDcard: ''}
      ],
      peopleCount: 1,
      appointmentTel: '',
      appointmentAlterTel: '',
      appointmentIDcard: '',
      notes: ''
    },
    result: false, //防止表单重复提交
    resultBtn: "完成",
    censusList: [], //户籍地数据存储
    rules
  }),
  computed: {
    ...mapGetters(['getMaxPeople']),
    ...mapState({
      seaBasic: state => state.Sea.seaBasic,  //获取上一个组件提交的信息
      partnerId: state => state.user.partnerId,
      userInfo: state => state.user.userInfo,
      options: state => state.Sea.seaArea
    }),
  },
  watch: {
    result(val) {
      val ? this.resultBtn = '表单正在提交中' : '完成'
    },
    'ruleForm.cremainsCount'(currentValue, oldValue) {
      let addDead = () => {
        this.ruleForm.departedList.push({userName: '', userLocation: '', userIDcard: ''})
      };
      let deleteDead = () => {
        this.ruleForm.departedList.splice(currentValue, 1)
      };
      (currentValue - oldValue) > 0 ? addDead() : deleteDead()
    },
    deep: true
  },
  created() {
    this.ruleForm.appointmentName = this.userInfo.name
    this.ruleForm.appointmentTel = this.userInfo.mobile
    this.ruleForm.appointmentAlterTel = this.userInfo.mobile
    this.ruleForm.appointmentIDcard = this.userInfo.id_card
  },
  mounted() {
    let inputs =	document.getElementsByName("cremainsCount")//通常获取的是表单标签name
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = "disabled"
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //临时处理数据格式提交
          if (this.seaBasic.appointmentPurpose == '祭祀') {
            delete (this.ruleForm['departedList'])
          }
          if (this.seaBasic.appointmentPurpose == '代撒') {
            this.$set(this.ruleForm, 'peopleCount', 0)
          }
          let newParams = Object.assign(this.ruleForm, this.seaBasic)
          newParams['user'] = {id: this.userInfo.id}
          newParams['partner'] = {id: this.partnerId}
          this.result = true
          this.$api.website.postFuneralWebSave(newParams)
            .then( dates => {
              this.$emit('changeActive', 5)
            }).catch( err => {
            this.result = false
              this.$store.commit('showMessage', {
                message: err
              })
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    changePeople(currentValue, oldValue) {
      const h = this.$createElement
      this.$set(this.ruleForm, 'peopleCount', currentValue)
      if (currentValue >= this.getMaxPeople) {
        this.$notify({
          title: '提示',
          message: h('i', { style: 'color: teal'}, `当前方向预约人数已超过最大可预约人数${this.getMaxPeople}人`)
        });
      }
    },
    _renderFormCard(domain, index) {
      if (this.seaBasic.appointmentPurpose == '报名') {
        return (
          <el-form-item
            label={`故 者${index+1}身份证号：`}
            prop={'departedList.' + index + '.userIDcard'}
          >
            <el-input v-model={domain.userIDcard} placeholder={`请填写海葬故者${index+1}的身份证号`}></el-input>
          </el-form-item>
        )
      }
    },
    _renderFormDate() {
      let appointmentName = (
        <el-form-item label="报名人名字：" prop="appointmentName" rules={this.rules.Name}>
          <el-input v-model={this.ruleForm.appointmentName} placeholder="请填写报名人的名字"></el-input>
        </el-form-item>
      )

      let appointmentIDcard = (
        <el-form-item label="身份证号：" prop="appointmentIDcard" rules={rules.checkIdNum}>
          <el-input v-model={this.ruleForm.appointmentIDcard} placeholder="请填写报名人身份证号码"></el-input>
        </el-form-item>
      )

      let appointmentTel = (
        <el-form-item label="联 系 电 话：" prop="appointmentTel" rules={this.rules.phone}>
          <el-input v-model={this.ruleForm.appointmentTel} placeholder="请填写报名人的联系电话"></el-input>
        </el-form-item>
      )

      let censusRegisterWay = (domain, index) => {
        if (this.seaBasic.address == '沈阳市') {
          return (
            <el-form-item
              label={`故 者${index+1}户 籍：`}
              prop={'departedList.' + index + '.userLocation'}
              rules={this.rules.censusRegister}
            >
              <el-select
                v-model={domain.userLocation}
                multiple={false}
                filterable={true}
                allow-create={true}
                default-first-option={true}
                placeholder={`请选择或输入故者${index+1}户籍`}>
                {
                  this.options.map(data =>
                    <el-option key={data} label={data} value={data}></el-option>
                  )
                }
              </el-select>
            </el-form-item>
          )
        } else {
          return (
            <el-form-item
              label={`故 者${index+1}户 籍：`}
              prop={'departedList.' + index + '.userLocation'}
              rules={this.rules.inCensusRegister}
            >
              <el-input v-model={domain.userLocation} placeholder={`请输入外地故者${index+1}户籍`}></el-input>
            </el-form-item>
          )
        }
      }
      let departedList = (
        this.ruleForm.departedList.map((domain, index) =>
          <div>
            <el-form-item
              label={`故 者${index+1}姓 名：`}
              prop={'departedList.' + index + '.userName'}
              rules={this.rules.deadName}
            >
              <el-input v-model={domain.userName} placeholder={`请填写海葬故者${index+1}的名字`}></el-input>
            </el-form-item>
            {/*{*/}
            {/*  this._renderFormCard(domain, index)*/}
            {/*}*/}
            <el-form-item
              label={`故 者${index+1}身份证号：`}
              prop={'departedList.' + index + '.userIDcard'}
            >
              <el-input v-model={domain.userIDcard} placeholder={`请填写海葬故者${index+1}的身份证号`}></el-input>
            </el-form-item>
            {censusRegisterWay(domain, index)}
          </div>
        )
      )

      let renderCountPrompt = () => {
        if (this.ruleForm.peopleCount > 3 ){
          return (
            <div class="sea-userInfo-remark">备注：请注意禁止虚报人数</div>
          )
        } else {
          return (
            <div class="sea-userInfo-remark">备注：禁止13周岁以下70周岁以上人员参加</div>
          )
        }
      }

      let peopleCount = (
        <el-form-item label="参 加 人 数：">
          <el-input-number value={this.ruleForm.peopleCount}  onChange={(currentValue, oldValue) => this.changePeople(currentValue, oldValue)} min={1} max={this.getMaxPeople} label="描述文字"></el-input-number>
          {renderCountPrompt()}
        </el-form-item>
      )

      let cremainsCountLabel
      this.seaBasic.appointmentPurpose == '祭祀' ? cremainsCountLabel = '祭祀故者数量：' : cremainsCountLabel = '故 者 数 量：'
      let cremainsCount = (
        <el-form-item label={cremainsCountLabel}>
          <el-input-number v-model={this.ruleForm.cremainsCount} name="cremainsCount" type="number" min={1} label="描述文字"></el-input-number>
        </el-form-item>
      )
      switch (this.seaBasic.appointmentPurpose) {
        case "祭祀":
          return (
            <div>
              {appointmentName}
              {appointmentIDcard}
              {appointmentTel}
              {peopleCount}
              {cremainsCount}
            </div>
          )
        case "代撒":
          return (
            <div>
              {appointmentName}
              {appointmentIDcard}
              {cremainsCount}
              {departedList}
              {appointmentTel}
            </div>
          )
        default:
          return (
            <div>
              {appointmentName}
              {appointmentIDcard}
              {cremainsCount}
              {departedList}
              {peopleCount}
              {appointmentTel}
            </div>
          )
      }
    }
  },
  render() {
    return (
      <div class="sea-userInfo">
        <div class="sea-userInfo-from">
          <el-form props={{ model: this.ruleForm }} ref="ruleForm">
            {this._renderFormDate()}
            <el-form-item label="备 用 电 话：">
              <el-input v-model={this.ruleForm.appointmentAlterTel} placeholder="请预留一份可联系的电话"></el-input>
            </el-form-item>
            <el-form-item label="备       注：">
              <el-input v-model={this.ruleForm.notes} placeholder=""></el-input>
            </el-form-item>
            <div class="sea-userInfo-btn">
              <button type="button" onClick={() => this.$emit('changeActive', 3)} class="agree">上一步</button>
              <button type="button" disabled={this.result} onClick={()=> this.submitForm('ruleForm')} class={`agree ${this.result ? 'not-cot' : ''}`}>{this.resultBtn}</button>
            </div>
          </el-form>
        </div>
      </div>
    )
  }
}
