<template>
  <div class="default-burial">
    <div class="none"></div>
    <div class="burial-apponit">
      <div class="container-nav clearfix">
        <!--步骤条-->
        <div class="sea-burial-nav">
          <div class="sea-burial-title">
            <h4>海葬报名</h4>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>海葬报名</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="sea-burial-steps">
            <el-steps :active="active" align-center finish-status="finish">
              <el-step title=""></el-step>
              <el-step title="海葬须知"></el-step>
              <el-step title="基本信息"></el-step>
              <el-step title="用户信息"></el-step>
              <el-step title=""></el-step>
            </el-steps>
            <div class="group-logo" v-if="active !== 5">
<!--              <img class="group-right" src="../../assets/images/sea-burial/sea-burial-border-rig.png"/>-->
              <div class="gropu-bottom"></div>
            </div>
          </div>
          <!--内容区-->
          <div class="sea-burial-content">
            <keep-alive>
              <component
                :is="pageView"
                @changeActive="handleChangeActive"
              ></component>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
    <xgj-modal v-model="showRealName" width="520px" :lockScroll="false" :closeModal="true" @close="showRealName=false">
      <div class="update-main">
        <div class="update-title"><h4>实名用户信息</h4></div>
        <div>
          <update-user @submitSuccess="showRealName=false"></update-user>
        </div>
      </div>
    </xgj-modal>
  </div>
</template>

<script>
  import SeaGuidelines from '@/components/sea-burial/sea-guidelines.js'
  import SeaBasic from '@/components/sea-burial/sea-basic.js'
  import SeaUserInfo from '@/components/sea-burial/sea-userInfo.js'
  import SeaSuccess from '@/components/sea-burial/sea-success.js'
  import updateUser from '@/components/personal-schedule/update-user.js'
  import Logindialog from '@/plugins/login/main'
  import util from "@/utils/util";
  import { mapState } from 'vuex'

  export default {
    name: 'sea-burial',
    components: {
      SeaGuidelines,
      SeaBasic,
      SeaUserInfo,
      SeaSuccess,
      updateUser
    },
    data() {
      return {
        active: 2,
        pageView: SeaGuidelines,
        showRealName: false
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        token: state => state.user.token
      })
    },
    created() {
      if (this.token && this.token !== 'undefined') {
        if (!this.userInfo.id_card) {
          this.showRealName = true
        }
      } else {
        Logindialog({visible: true})
      }
    },
    watch: {
      active(val) {
        switch (val) {
          case 2:
            this.pageView = SeaGuidelines;
            break;
          case 3:
            this.pageView = SeaBasic;
            break;
          case 4:
            this.pageView = SeaUserInfo;
            break;
          case 5:
            this.pageView = SeaSuccess;
            break;
        }
      }
    },
    methods: {
      handleChangeActive(data) {
        if (data) {
          this.active = data;
        } else {
          if (this.token && this.token !== 'undefined') {
            if (!this.userInfo.id_card) {
              this.showRealName = true
            }
          } else {
            Logindialog({visible: true})
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .update-main {
    padding: 38px;
    .update-title {
      margin-bottom: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        border-left: 4px solid #3281EC;
        padding-left: 7px;
        color: #666666;
        position: relative;
          &::after {
             content: '';
             width: 100%;
             background: #3281EC;
             height: 1px;
             display: block;
             position: absolute;
             left: 0;
             bottom: -7px;
             z-index: 22;
           }
      }
    }
  }
  @media (max-width: 575.98px) {
    /deep/ .el-step__title {
      font-size: 8px;
    }
  }
  .default-burial {
    position: relative;
    background: url("../../assets/images/sea-burial/new-sea-index-bg.png") no-repeat top #ffffff;
    width: 100%;
    .none {
      height: 300px;
    }
    .burial-apponit {
      width: 100%;
      position: relative;
      padding-bottom: 40px;
      background: url("../../assets/images/sea-burial/new-sea-index-bot-bg.png") no-repeat bottom #ffffff;

      .container-nav {
        margin: 0 auto;
        @include screen-size;
      }

      .sea-burial-nav {
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        opacity: 0.8;
        .sea-burial-title {
          padding: 23px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h4 {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #666666;
            line-height: 24px;
            border-left: 5px solid #3281EC;
            position: relative;
            padding-left: 8px;
            &::after {
               content: '';
               width: 98px;
               height: 2px;
               background: #3281EC;
               position: absolute;
               bottom: -8px;
               left: 8px;
             }
          }
        }
        .sea-burial-content {
          padding: 0 30px;
        }
        .sea-burial-steps {
          padding: 38px 30px 0;
          .group-logo {
            width: 100%;
            margin-top: 38px;
            position: relative;
            .group-right {
              position: absolute;
              right: 0;
              bottom: -4px;
            }
            .gropu-bottom {
              width: 100%;
              height: 1px;
              background: #0657A2;
              border: #0657A2;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }

    .sea-breadcrumb {
      background: #ffffff;
      padding: 18px 10px;
      /deep/ .el-breadcrumb{
        font-size: 18px;
      }
    }
  }
</style>
