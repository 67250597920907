import './sea-burial.scss'

import rules from "../../utils/rules";

export default {
  data: () => ({
    ruleForm: {
      periodSite: '',
      appointmentPurpose: '',
      periodId: '',
      address: ''
    },
    datePicker: [],
    holdsPull: true, //默认可预约状态
    dateIndex: null,
    rules
  }),
  created() {
    this.$store.dispatch('getSeaArea')
    this.$set(this.ruleForm, 'periodSite', this.$route.query.periodSite)
    this.options = {
      text: `您已报名参加${this.ruleForm.periodSite}海葬方向`,
      icon:'', // 左侧图标(不需要icon不传)
      iconSize: '16px', // icon大小（正方形默认16px）
      size: '16px', // 通知内容文字大小（默认16px）
      color: '#3281ec', // 通知内容文字颜色(默认#f60)
      backround: '#fff', //背景颜色(默认#fff7cc)
      delay: '1000', // 动画延迟时间(默认一秒后开始滚动，单位毫秒)
      speed: '20' // 滚动速率默认50 (px/s)
    }
    this.directionChange()
  },
  watch: {
    'ruleForm.appointmentPurpose'(val) {
      if (this.dateIndex != null) {
        if (val == '代撒') {
          this.$set(this, 'holdsPull', true)
        } else {
          this.changeDate(this.datePicker[this.dateIndex], this.dateIndex)
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$emit('changeActive', 2)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.holdsPull) {
            this.$message.error('当前可预约剩余人数为0')
          } else {
            this.$store.commit('updateSeaBasic', this.ruleForm)
            this.$emit('changeActive', 4)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    directionChange() {
      const that = this;
      that.$api.website.getFuneralWebList({periodSite: that.ruleForm.periodSite})
        .then( dates => {
          that.datePicker = []
          if (dates.data.length > 0) {
            dates.data.forEach( data => {
              that.datePicker.push(data)
            })
          } else {
            this.$set(this, 'holdsPull', false)
          }
        }).catch( err => {
          that.$store.commit('showMessage',{
            message: err
          })
        })
    },
    changeDate(date, index) {
      this.dateIndex = index
      date.max_people - date.sum_people > 0 ?  this.$set(this, 'holdsPull', true) : this.$set(this, 'holdsPull', false)
      this.$store.commit('updateMaxPeople', date.max_people - date.sum_people)
    },
    _renderSubmitBtn() {
      if (this.holdsPull) {
        return (
          <button type="button" onClick={() => this.submitForm('ruleForm')}>下一步</button>
        )
      } else {
        return (
          <button type="button" style={{'background': '#ededed' , 'color': '#fff'}}>暂无可预约的数据</button>
        )
      }
    },
    _renderFormData() {
      let _renderPeriodIdRadio = (ele, index) => {
        if (this.ruleForm.appointmentPurpose == '代撒') {
          return (
            <el-radio key={ele.id} label={ele.id} border>{ele.period_year}/{ele.period_time}</el-radio>
          )
        } else {
          return (
            <el-radio onChange={() => this.changeDate(ele, index)} key={ele.id} label={ele.id} border>{ele.period_year}/{ele.period_time}(余{ele.max_people - ele.sum_people}个)</el-radio>
          )
        }
      }
      let periodId = (
        <el-form-item label="日 期 选 择：" prop="periodId" rules={this.rules.Change}>
          <el-radio-group v-model={this.ruleForm.periodId} size="mini">
            { this.datePicker.map((ele, index) =>
              _renderPeriodIdRadio(ele, index)
            )}
          </el-radio-group>
        </el-form-item>
      )

      let address = (
        <el-form-item label="故 者 户 籍：" prop="address" rules={this.rules.Change}>
          <el-radio-group v-model={this.ruleForm.address} class="multiple-choice">
            <el-radio label="沈阳市">沈阳市</el-radio>
            <el-radio label="外地">外地</el-radio>
          </el-radio-group>
        </el-form-item>
      )

      switch(this.ruleForm.appointmentPurpose) {
        case "报名":
          return (
            <div>
              {address}
              {periodId}
            </div>
          )
        case "祭祀":
          return (
            <div>
              {periodId}
            </div>
          )
        case "代撒":
          return (
            <div>
              {address}
              {periodId}
            </div>
          )
      }
    }
  },
  render () {
    return(
      <div class="sea-basic">
        <div class="sea-basic-from">
          <el-form props={{ model: this.ruleForm }} ref="ruleForm">
            <div style="margin-bottom: 22px;text-align: center;color: #3281ec;">
              您正在报名{this.ruleForm.periodSite}方向
            </div>
            <el-form-item label="海 葬 服 务：" prop="appointmentPurpose" rules={this.rules.Change}>
              <el-radio-group v-model={this.ruleForm.appointmentPurpose} class="multiple-choice">
                <el-radio label="报名">报名</el-radio>
                <el-radio label="祭祀">祭祀</el-radio>
                <el-radio label="代撒">代撒</el-radio>
              </el-radio-group>
            </el-form-item>
            {this._renderFormData()}
            <div class="sea-basic-btn">
              <button type="button" onClick={() => this.goBack()}>上一步</button>
              {this._renderSubmitBtn()}
            </div>
          </el-form>
        </div>
      </div>
    )
  }
}
