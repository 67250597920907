import './sea-burial.scss'
import {mapState} from "vuex";

export default {
  methods: {
    backHome() {
      this.$router.push({
        path: '/'
      })
    }
  },
  computed: {
    ...mapState({
      orgInfo: state => state.user.orgInfo
    })
  },
  render() {
    return (
      <div class="sea-success">
        <div class="sea-success-bg">
          <img src={require('../../assets/images/sea-burial/sea-burial-success.png')} />
        </div>
        <div class="sea-success-status">
          <h2 class="status-title text-center">预约成功！</h2>
          <div class="sea-success-code">
            <img src={this.orgInfo.wx_image.url} />
            <div class="code-left">
              <p>在个人中心查看预约详情</p>
              <p>或扫描关注沈阳海葬公众号进行查看</p>
            </div>
          </div>
          <p class="text-center" style="text-align: center;"><span>注意事项：</span>请接收查看手机短信了解办理日期及相关手续</p>
          <div class="back-home">
            <button type="button" onClick={() => this.backHome()}>返回首页</button>
          </div>
        </div>
      </div>
    )
  }
}
