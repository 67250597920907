var QQV = (rule, value, callback) => {
  //debugger
  if (value === '') {
    callback(new Error('输入QQ号'))
  } else if (/^[1-9][0-9]{4,10}$/.test(value)) {
    callback()
  } else {
    callback(new Error('输入正确的QQ号'))
  }
}

// 类似金钱,首位不为0,最多2位小数
export function checkNumPot2(rule, value, callback) {
  const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
  if (!value) {
    return callback(new Error('请填写数字'))
  } else if (!reg.test(value)) {
    return callback(new Error('请填写数字,最多2位小数'))
  } else {
    callback()
  }
}

// 身份证
export function checkIdNum(rule, value, callback) {
  const reg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
  if (!value) {
    return callback(new Error('证件号码不能为空'))
  } else if (!reg.test(value)) {
    return callback(new Error('证件号码不正确'))
  } else {
    callback()
  }
}

// 整数
export function checkInterNum(rule, value, callback) {
  const reg = /^[0-9]*[1-9][0-9]*$/
  if (!value) {
    return callback(new Error('请填写整数'))
  } else if (!reg.test(value)) {
    return callback(new Error('请输入整数'))
  } else {
    callback()
  }
}

//中文
export function chinese(rule, value, callback) {
  let reg = /^[\u4e00-\u9fa5]+$/gi;
  if (!value) {
    return callback(new Error('请填写中文'))
  } else if (!reg.test(value)) {
    return callback(new Error('请输入中文'))
  } else {
    callback()
  }
}

export default {
  QQ: [{ required: true, validator: QQV, trigger: 'blur' }],
  phone: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '目前只支持中国大陆的手机号码', trigger: 'blur' }],
  numPot2: [{ required: true, validator: checkNumPot2, trigger: 'blur' }],
  checkIdNum: [{ required: true, validator: checkIdNum, trigger: 'blur' }],
  chinese: [{ required: true, validator: chinese, trigger: 'blur' }],
  InterNum: [{ required: true, validator: checkInterNum, trigger: 'blur' }],
  Name: [{ required: true, validator: chinese, trigger: 'blur' }],
  Change: [{ required: true, message: '请选择内容', trigger: 'change' }],
  deadName: [{ required: true, message: '请填写海葬故者的名字', trigger: 'blur' }],
  censusRegister: [{ required: true, message: '请选择海葬故者的户籍', trigger: 'change' },{ min: 2, max: 30, message: '长度在 12 到 30 个字符', trigger: 'change' }],
  inCensusRegister: [{ required: true, message: '请填写海葬故者的户籍', trigger: 'blur' },{ min: 2, max: 30, message: '长度在 12 到 30 个字符', trigger: 'blur' }],
  smsCode: [{ required: true, validator: checkInterNum,  message: '请输入短信验证码', trigger: 'blur' },{ min: 6, max: 6, message: '长度在 6 个字符', trigger: 'blur' }],
  fillOut: [{ required: true, message: '输入框内容不能为空', trigger: 'blur' }]
}
