import './schedule-schedule.scss'

import rules from '@/utils/rules'
import _ from 'lodash'
import {mapState} from 'vuex'

export default {
  data: () => ({
    ruleForm: {
      name: '',
      idCard: '',
      mobile: '',
      verifyCode: '',
      appName: process.env.VUE_APP_MP_NAME
    },
    rules,
    codeTitle: '发送短信验证码',
    timer: null,
    isActive: false, //验证码状态
  }),
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  created() {
    if(this.userInfo.id_card) {
      this.ruleForm.name = this.userInfo.name;
      this.ruleForm.idCard = this.userInfo.id_card;
      this.ruleForm.mobile = this.userInfo.mobile
    }
  },
  methods: {
     submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm['userId'] = this.userInfo.id;
          this.$api.website.postUserUpdate(this.ruleForm)
            .then(dates => {
              this.$store.commit('showMessage', {
                message: '修改成功',
                type: 'success'
              });
              clearInterval(this.timer);
              this.ruleForm.verifyCode = '';
              this.codeTitle = '发送短信验证码';
              this.isActive = false;
              this.$store.dispatch('getCurrentUser');
              this.$emit('submitSuccess')
            }).catch(err => {
            this.$store.commit('showMessage', {
              message: '保存失败',
              type: 'error'
            })
          })
        } else {
          console.log('error submit!!');
          return false
        }
      });
    },
    async validID(rule, value, callback) {
      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('身份证号码不正确'))
      }
    },
    getCode() {
      const _this = this;
      this.$refs.ruleForm.validateField("mobile", errMsg => {
        if (errMsg) {
          return;
        }
        let parms = {
          mobile: _this.ruleForm.mobile,
          appName: process.env.VUE_APP_MP_NAME,
          type: 2
        };
        _this.timer = setInterval(CountDown, 1000);
        let maxTime = 60;

        function CountDown() {
          if (maxTime >= 0) {
            _this.codeTitle = maxTime + 's后重发';
            _this.isActive = true;
            --maxTime
          } else {
            clearInterval(_this.timer);
            _this.codeTitle = '重新发送';
            _this.isActive = false;
          }
        }

        _this.$api.system.getVerifycode(parms)
          .then(res => {
            console.log(res)
            if (res.status !== 200) {
              _this.codeTitle = '发送失败';
            }
          })
      });
    }
  },
  render() {
    return (
      <div class="personal-center-form">
        <el-form props={{model: this.ruleForm}} ref="ruleForm">
          <el-form-item label="真实姓名：" prop="name" rules={this.rules.chinese}>
            <el-input v-model={this.ruleForm.name}></el-input>
          </el-form-item>
          <el-form-item label="身份证号：" prop="idCard" rules={this.rules.checkIdNum}>
            <el-input v-model={this.ruleForm.idCard}></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="mobile" rules={this.rules.phone}>
            <el-input v-model={this.ruleForm.mobile}></el-input>
          </el-form-item>
          <el-form-item label="短信验证码" prop="verifyCode" rules={this.rules.smsCode}>
            <el-input v-model={this.ruleForm.verifyCode} placeholder="请填写短信验证码"></el-input>
            <button class={`create-from-btn ${this.isActive ? 'not-cursor' : ''}`} type="button"
                    onClick={() => this.getCode()} disabled={this.isActive}>{this.codeTitle}</button>
          </el-form-item>
          <div class="personal-center-btn">
            <button type="button" onClick={() => this.submitForm('ruleForm')}>确定</button>
          </div>
        </el-form>
      </div>
    )
  }
}
