import './sea-burial.scss'

import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    savaAgree() {
      if (!this.userInfo.id_card) {
        this.$emit('changeActive')
      } else {
        this.$emit('changeActive', 3)
      }
    }
  },
  render() {
    return (
      <div class="sea-guidelines">
        <p class="sea-guidelines-title">海葬用户须知</p>
        <div class="sea-guidelines-content">
          <p style="margin-left:0;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family: 宋体">一、</span><span style="font-size:16px;line-height:150%;font-family: 宋体">沈阳天路殡仪服务有限公司是不以盈利为目的的公益性单位，为沈阳市民政局唯一政府采购招标的海葬服务单位；</span>
          </p>
          <p style="margin-left:0;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family: 宋体">二、</span><span style="font-size:16px;line-height:150%;font-family: 宋体">凡是同意通过本须知，即清楚了解并接受沈阳天路殡仪服务有限公司提供的海葬殡葬服务；</span>
          </p>
          <p style="margin-left:0;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family: 宋体">三、</span><span style="font-size:16px;line-height:150%;font-family: 宋体">天路公司有权利根据注册客户的身体健康状态及</span><span style="font-size: 16px;line-height:150%;font-family:宋体;color:black">相关条件决定是否接纳报名；有权利核实注册客户的身份相关信息；并按照协议约定向注册客户收取全额海葬费用；有权利拒绝注册客户提出的超出合同约定的不合理要求；</span>
          </p>
          <p style="margin-left:0;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family:宋体;color:black">四、</span><span style="font-size:16px;line-height:150%;font-family:宋体;color:black">天路公司按照合同约定的内容和标准为注册客户提供海葬殡仪服务；</span>
          </p>
          <p style="margin-left:0;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family:宋体;color:black">五、</span><span style="font-size:16px;line-height:150%;font-family:宋体;color:black">注册客户必须对于海葬的骨灰具有处分权，并使用天路公司提供的骨灰海葬专用可降解骨灰容器；注册客户应提供逝者死亡证明、户口注销页等相关文件；注册客户应严格按照天路公司的要求和条件，参与海葬殡仪活动；</span>
          </p>
          <p style="margin-left:0;text-align:left;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family:宋体">六、</span><span style="font-size:16px;line-height:150%;font-family: 宋体">如发生不可抗力，注册客户应当听从天路公司的具体安排；</span>
          </p>
          <p style="margin-left:0;text-align:left;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family:宋体">七、</span><span style="font-size:16px;line-height:150%;font-family: 宋体">如注册客户未能按照约定支付海葬费用，或未能按照天路公司的海葬服务安排出行，则天路公司有权按照协议约定解除与注册客户的服务合同，并按照约定处理相关违约事宜；</span>
          </p>
          <p style="margin-left:0;text-align:left;text-indent:0;line-height:150%">
            <span style="font-size:16px;line-height:150%;font-family:宋体">八、</span><span style="font-size:16px;line-height:150%;font-family: 宋体">关于海葬殡仪服务的具体内容，沈阳天路殡仪服务有限公司享有最终解释权。</span>
          </p>
          <p>
            <br/>
          </p>
        </div>
        <div class="sea-guidelines-btn">
          <button onClick={() => this.savaAgree()} class="agree">同意</button>
        </div>
      </div>
    )
  }
}
